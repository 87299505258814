import * as React from 'react';
import '../core/polyfills';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Navigation } from '../components/navigation/navigation.component';
import { Footer } from '../components/footer/footer.component';
import { BlogPostBlock } from '../components/blog-post/blog-post.component';
import '../scss/styles.scss';
import lozad from 'lozad';

export default class extends React.Component<
  GatsbyProps<ContentfulProps<BlogPost>>
> {
  public observer: { observe: () => void; observer: IntersectionObserver };
  componentDidMount() {
    const el = document.querySelectorAll('.lazy');
    this.observer = lozad(el, {
      rootMargin: '420px 0px',
    });
    this.observer.observe();
  }

  componentWillUnmount() {
    this.observer.observer.disconnect();
  }
  render() {
    const {
      title,
      metaTitle,
      description,
      environment,
      className,
      slug,
      schemaDate,
      body,
    } = this.props.data.contentfulBlogPost;
    return (
      <div className={'image-hero ' + className}>
        <Helmet>
          <html lang="en" />
          <title>{metaTitle || title}</title>
          <meta
            name="description"
            content={description ? description.description : ''}
          />
          <link
            rel="canonical"
            href={`https://www.norsepower.com/post/${slug}/`}
          />

          <script type="application/ld+json">
            {`{
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://www.norsepower.com/post/${slug}"
      },
      "headline": "${title}",
      "image": [
        "https:${
          this.props.data.contentfulBlogPost.image.file.url
        }?w=1920&h=1080"
       ],
      "datePublished": "${schemaDate}",
      "dateModified": "${schemaDate}",
      "author": {
        "@type": "Organization",
        "name": "Norsepower"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Norsepower",
        "logo": {
          "@type": "ImageObject",
          "url": "https:${environment.logo.file.url}?fm=jpg"
        }
      },
      "description": "${description ? description.description : ''}"
    }`}
          </script>

          <link rel="preconnect" href="https://www.googletagmanager.com" />

          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-56FK6Z2');`}
          </script>

          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={`https://www.norsepower.com/post/${slug}`}
          />
          <meta
            property="og:title"
            content={title + ' - Norsepower Rotor Sails'}
          />
          <meta
            property="og:description"
            content={description ? description.description : ''}
          />
          <meta
            property="og:image"
            content={`https:${this.props.data.contentfulBlogPost.image.file.url}?w=1920&h=1080`}
          />
          <meta
            property="og:image:alt"
            content={title + ' - Norsepower Rotor Sails'}
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:width" content="1920" />
          <meta property="og:image:height" content="1080" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@Norsepowerltd" />
        </Helmet>

        <Navigation
          config={environment.navigation}
          logo={environment.logo}
          logoAlternative={environment.logoAlternative}
        />

        <BlogPostBlock fields={this.props.data.contentfulBlogPost} />
        <Footer
          config={environment.footer}
          logo={environment.logoAlternative}
        />
      </div>
    );
  }
}

export const postQuery = graphql`
  query BlogQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      metaTitle
      className
      slug
      publishedDate(formatString: "MMM DD, YYYY")
      schemaDate: publishedDate(formatString: "YYYY-MM-DD")
      description {
        description
      }
      image {
        file {
          url
        }
      }
      body {
        body
      }
      environment {
        logo {
          file {
            url
          }
        }
        logoAlternative {
          file {
            url
          }
        }
        navigation {
          links {
            title
            path
            children {
              title
              path
            }
          }
        }
        footer {
          left {
            path
            title
          }
          info {
            email
            address
          }
          right {
            path
            href
            title
          }
        }
      }
    }
  }
`;
