import * as React from 'react';
import { Image } from '../image/image.component';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import './blog-post.component.scss';
import { Asset } from 'contentful';

export function BlogPostBlock({ fields }: { fields: BlogPost }) {
  const readTime = Math.ceil(fields.body.body.length / 4200);

  const options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const { url } = node.data.target.fields.file['en-US'];
        return (
          <a href={'https:' + url} rel="noopener" target="_blank">
            {children}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const { url, alt } = getImageDetails(node);
        if (!url) {
          return null;
        }
        return (
          <img
            src={`https:${url}`}
            alt={alt || 'Untitled'}
            loading="lazy"
            style={{ width: '100%', maxWidth: '100%' }}
          />
        );
      },
    },
    // renderText: text => text.replace('!', '?'),
  };

  // console.log(documentToReactComponents(JSON.parse(fields.body.body), options));

  return (
    <div className="block-post-block">
      <header>
        <Image fields={fields.image} alt={fields.title} />
        <section className="info">
          <h1>{fields.title}</h1>
          <span className="details">
            {fields.publishedDate} <span className="seperator"> | </span>{' '}
            {readTime}
            min read
          </span>
        </section>
      </header>
      <article className={'article ' + fields.className}>
        {documentToReactComponents(JSON.parse(fields.body.body), options)}
      </article>
    </div>
  );
}

function getImageDetails(node: any): { url: string; alt?: string } {
  try {
    const { description, file } = node?.data?.target?.fields;
    const url = file['en-US']?.url;
    const alt = description['en-US'];
    return {
      url,
      alt,
    };
  } catch (error) {
    try {
      const { file } = node?.data?.target?.fields;
      const url = file['en-US']?.url;
      return {
        url,
      };
    } catch (err) {
      return {
        url: null,
      };
    }
  }
}
